
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    activeSubPath(path: string) {
      return this.$route.path.startsWith(path)
    },
  },
})
